import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setEmptyMessage } from "../slices/reports";
import { XMReportsModel, XMResultModel } from "../../models/reports";
import { getReportAttemps } from "../../services";
import { getLastReportAttemp, sortReportResultsByState, sortReportsByState } from "../../helpers";

export const getAsicReports = createAsyncThunk(
  "reports/getAsicReports",
  async (date: string, thunkApi) => {
    const { rejectWithValue } = thunkApi;

    try {
      const res = await api.get<XMReportsModel[]>(
        `/electricity-supply-service/device/asic-reports/since/${date}/until/${date}/`
      );

      if (res.data.length === 0) {
        thunkApi.dispatch(setEmptyMessage(`No se encontraron reportes para la fecha ${date}`));
      }

      const data = res.data.map(async (item) => {
        const reportAttemps = await getReportAttemps(item.id);
        return { ...item, last_attemp: getLastReportAttemp(reportAttemps) };
      });

      const reports = await Promise.all(data);
      return reports.sort(sortReportsByState);
    } catch (error) {
      rejectWithValue(error);
      return [];
    }
  }
);

export const getReportDetail = createAsyncThunk(
  "reports/getReportDetail",
  async (asicCode: string) => {
    const res = await api.get<XMResultModel>(
      `/electricity-supply-service/device/asic-report/process-result/${asicCode}/xm-soap-service/`
    );

    const data = {
      ...res.data,
      Results: { BorderResult: res.data.Results.BorderResult.sort(sortReportResultsByState) },
    };

    return data;
  }
);
