import { api } from "@enerbit/base";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "../store";
import { MeterEventConsult } from "../../models/frontiers";

export const getMeterEvents = createAsyncThunk(
  "measuringBoard/getMeterEvents",
  async ({ meterSerial }: MeterEventConsult, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const currentPage = state.events.currentPage;
    const since = state.events.since;
    const until = state.events.until;

    const tempSince = since
      ? `${since}T00:00:00`
      : `${moment().subtract(1, "days").format("YYYY-MM-DD")}T00:00:00`;
    const tempUntil = until ? `${until}T23:59:59` : `${moment().format("YYYY-MM-DD")}T23:59:59`;

    const res = await api.get(
      `/meters/meters-events/?start_datetime=${encodeURIComponent(
        tempSince
      )}&end_datetime=${encodeURIComponent(
        tempUntil
      )}&meter_serial=${meterSerial}&page=${currentPage}&size=20`
    );

    return res.data;
  }
);
