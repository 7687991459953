export interface ReportsState {
  reports: XMReportsModel[];
  totalPages: number;
  currentPage: number;
  loading: boolean;
  reportDate: string | null;
  emptyReports: boolean;
  emptyMessage: string;
  reportResults: XMResultModel | null;
  tempReportResults: XMResultModel | null;
  error: boolean;
  resultSearchValue: string;
}

export interface XMReportsModel {
  minimum_operation_date: string;
  maximum_operation_date: string;
  blob_path: string;
  id: string;
  created_at: string;
  updated_at: string;
  asic_received_code: string;
  reported_at: string;
  last_attemp: ReportAttemps | null;
}

export interface XMResultModel {
  HasValidationErrors: boolean;
  ProcessDone: boolean;
  ResultFlag: string;
  Results: Results;
}

export interface Results {
  BorderResult: BorderResult[];
}

export interface BorderResult {
  Code: string;
  ResultFlag: ResultFlags;
  ErrorMessage: string;
  HasValidationErrors: boolean;
  ValidationErrorMessage: string | null;
}

export enum ResultFlags {
  SUCCESS = "Success",
  ERROR = "Error",
}

export interface ReporAttempsResponse {
  items: ReportAttemps[];
  page: number;
  size: number;
  total: number;
  pages: number;
  next_page: number;
  previous_page: number;
}

export interface ReportAttemps {
  report_id: string;
  process_id: string;
  state: string;
  has_xm_response: boolean;
  result: string;
  id: string;
  created_at: string;
  updated_at: string;
}

export enum ReportAttempsStates {
  NO_SENT = "NoSent",
  WARNING = "Warning",
  WAITING = "Waiting",
  SUCCESS = "Success",
  ERROR = "Error",
}
