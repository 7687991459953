import {
  Box,
  Button,
  CloseIcon,
  Modal,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from "@enerbit/base";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useState } from "react";
import { RestoreSchedulesStatuses } from "../../models/frontiers";
import moment from "moment";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "650px",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
};

const StatusDetailModal = ({ open, handleClose }: Props) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const restoreStatus = useSelector((state: RootState) => state.frontiers.restoreScheduleStatus);
  const loadingRestoreStatus = useSelector(
    (state: RootState) => state.frontiers.loadingRestoreStatus
  );
  const errorRestoreStatus = useSelector((state: RootState) => state.frontiers.errorRestoreStatus);
  const statusColors = useSelector(
    (state: RootState) => state.frontiers.restoreSchedulesStatusColors
  );

  const statusData: { title: string; data: string; isDate?: boolean }[] = [
    {
      title: "Datos recuperados",
      data: `${restoreStatus.total_periods_found} / ${restoreStatus.total_periods_requested}`,
    },
  ];
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2 }}>
          <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
            Detalle del comando
          </Typography>
          <Button onClick={handleClose} variant='outlined' className='back-btn'>
            <CloseIcon />
          </Button>
        </Box>
        <DataContainer bgcolor={statusColors.bgcolor} fontcolor={statusColors.color}>
          <Stepper
            orientation='vertical'
            sx={{
              ".MuiStepConnector-line": {
                borderColor: statusColors.color,
              },
            }}>
            <Step>
              <StepLabel StepIconComponent={() => <FiberManualRecordIcon />}>
                <Typography sx={{ color: statusColors.color }}>
                  Datos recuperados:{" "}
                  <strong>
                    {restoreStatus.total_periods_found}/{restoreStatus.total_periods_requested}
                  </strong>
                </Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={() => <FiberManualRecordIcon />}>
                <Typography sx={{ color: statusColors.color }}>
                  {restoreStatus.state === RestoreSchedulesStatuses.FAILED
                    ? "Se intentó recuperar "
                    : "Se recuperó "}{" "}
                  desde{" "}
                  <strong>
                    {moment(restoreStatus.start_datetime_restore_schedules).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </strong>{" "}
                  hasta{" "}
                  <strong>
                    {moment(restoreStatus.end_datetime_restore_schedules).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  </strong>
                </Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={() => <FiberManualRecordIcon />}>
                <Typography sx={{ color: statusColors.color }}>
                  Fecha de creación del comando:{" "}
                  <strong>{moment(restoreStatus.created_at).format("YYYY-MM-DD HH:mm:ss")}</strong>
                </Typography>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel StepIconComponent={() => <FiberManualRecordIcon />}>
                <Typography sx={{ color: statusColors.color }}>
                  Comando creado por: <strong>{restoreStatus.user}</strong>
                </Typography>
              </StepLabel>
            </Step>
          </Stepper>
        </DataContainer>
      </Box>
    </Modal>
  );
};

export default StatusDetailModal;

const DataContainer = styled(Box)<{ bgcolor: string; fontcolor: string }>(
  ({ bgcolor, fontcolor }) => ({
    backgroundColor: bgcolor,
    color: fontcolor,
    padding: "24px",
    borderRadius: "12px",
  })
);
