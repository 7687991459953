import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit";
import { getAsicReports, getReportDetail } from "../thunks/reports";
import { ReportAttemps, ReportsState, XMReportsModel, XMResultModel } from "../../models/reports";

const initialState: ReportsState = {
  reports: [],
  totalPages: 0,
  currentPage: 0,
  loading: false,
  reportDate: null,
  emptyReports: false,
  error: false,
  emptyMessage: "",
  reportResults: null,
  tempReportResults: null,
  resultSearchValue: "",
};

export const reportsSlice: Slice<ReportsState> = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setDate: (state, { payload }) => {
      state.reportDate = payload;
    },
    setEmptyReports: (state, { payload }) => {
      state.emptyReports = payload;
    },
    setEmptyMessage: (state, { payload }) => {
      state.emptyMessage = payload;
    },
    setFetchError: (state, { payload }: PayloadAction<boolean>) => {
      state.error = payload;
    },
    updateReportAttempState: (
      state,
      { payload }: PayloadAction<{ newState: string; reportIndex: number }>
    ) => {
      const tempReport = state.reports[payload.reportIndex];

      if (tempReport.last_attemp) {
        tempReport.last_attemp.state = payload.newState;
      }

      state.reports[payload.reportIndex] = tempReport;
    },
    updateLastAttemp: (
      state,
      { payload }: PayloadAction<{ lastAttemp: ReportAttemps | null; reportId: string }>
    ) => {
      const { lastAttemp, reportId } = payload;
      const reportIndex = state.reports.findIndex((item) => item.id === reportId);
      const report = state.reports[reportIndex];
      report.last_attemp = lastAttemp;
      state.reports[reportIndex] = report;
    },
    setResultSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.resultSearchValue = payload;
    },
    handleResultSearch: (state) => {
      if (!state.reportResults || !state.tempReportResults || !state.resultSearchValue) return;

      const completeFrts = state.reportResults.Results.BorderResult;
      const filteredFrts = completeFrts.filter((item) =>
        item.Code.toLowerCase().includes(state.resultSearchValue.toLowerCase())
      );

      state.tempReportResults.Results.BorderResult = filteredFrts;
    },
    resetSearch: (state) => {
      if (!state.reportResults || !state.tempReportResults) return;
      state.resultSearchValue = "";
      state.tempReportResults.Results.BorderResult = state.reportResults.Results.BorderResult;
    },
    resetState: (state) => {
      state.error = false;
      state.loading = false;
      state.reports = [];
      state.emptyReports = false;
      state.reportDate = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAsicReports.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAsicReports.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reports = payload;
      state.emptyReports = payload.length === 0;
    });
    builder.addCase(getAsicReports.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getReportDetail.pending, (state) => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getReportDetail.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reportResults = payload;
      state.tempReportResults = payload;
    });
    builder.addCase(getReportDetail.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const {
  setDate,
  setEmptyReports,
  setEmptyMessage,
  setFetchError,
  updateReportAttempState,
  updateLastAttemp,
  setResultSearchValue,
  handleResultSearch,
  resetSearch,
  resetState,
} = reportsSlice.actions;
