import { createSlice } from "@reduxjs/toolkit";
import { getMeterEvents } from "../thunks/events";
import { EventsState } from "../../models/events";

const initialState: EventsState = {
  error: false,
  isLoading: false,
  events: [],
  totalPages: 0,
  currentPage: 0,
  since: undefined,
  until: undefined,
};

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setSince: (state, { payload }) => {
      state.since = payload;
    },
    setUntil: (state, { payload }) => {
      state.until = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeterEvents.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(getMeterEvents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = false;
      state.events = payload.items;
      state.totalPages = payload.pages;
    });
    builder.addCase(getMeterEvents.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
  },
});

export const { setCurrentPage, setSince, setUntil } = eventsSlice.actions;
