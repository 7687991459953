import { Box, Button, CachedIcon, SearchIcon, TextField } from "@enerbit/base";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { handleResultSearch, resetSearch, setResultSearchValue } from "../../store/slices/reports";

const ReportResultsSearchBar = () => {
  const searchValue = useSelector((state: RootState) => state.reports.resultSearchValue);
  const dispatch = useDispatch<AppDispatch>();

  const handleSearch = () => {
    dispatch(handleResultSearch({}));
  };

  const handleResetSearch = () => {
    dispatch(resetSearch({}));
  };

  return (
    <Box sx={{ display: "flex", width: "600px" }}>
      <TextField
        fullWidth
        placeholder='Buscar por FRT'
        value={searchValue}
        onChange={(e) => dispatch(setResultSearchValue(e.target.value))}
        sx={{ borderRadius: "12px 0px 0px 12px !important" }}
        InputProps={{
          sx: {
            "& fieldset": {
              borderRadius: "12px 0px 0px 12px !important",
            },
          },
        }}
      />
      <Button
        onClick={handleSearch}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "0px 12px 12px 0px !important",
        }}
        variant='contained'
        color='primary'>
        <SearchIcon />
      </Button>
      <Button variant='contained' sx={{ ml: 2 }} onClick={handleResetSearch}>
        <CachedIcon />
      </Button>
    </Box>
  );
};

export default ReportResultsSearchBar;
