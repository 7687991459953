import { useState } from "react";
import { ArrowForwardIcon, Box, Button, Typography } from "@enerbit/base";
import { useNavigate, useNavigation } from "react-router-dom";
import { Handle, Position } from "reactflow";
import { Tooltip as ReactTooltip } from "react-tooltip";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const MeterNode = ({ data }: any) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = () => {
    copy(data.serial);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box sx={{ width: "120px", borderColor: "red", backgroundColor: "#fff", borderRadius: "3px" }}>
      <Box
        sx={{
          width: "100%",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <Typography sx={{ fontSize: "7px", color: "#667085" }}>Medidor</Typography>
        <Box
          onClick={() => {
            navigate(`/detail/${data.serial}`, { replace: true });
            // navigate(0);
          }}
          sx={{
            height: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}>
          <ArrowForwardIcon color='secondary' sx={{ fontSize: "10px" }} />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: data.serial === data.currentSerial ? "#EFE9FB" : "#E4E7EC",
          padding: "5px",
          height: "45px",
          borderRadius: "0px 0px 3px 3px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "3px",
        }}>
        <Typography
          onClick={() => {
            navigate(`/detail/${data.serial}`, { replace: true });
          }}
          color={"primary"}
          fontWeight={700}
          sx={{ fontSize: "14px", cursor: "pointer", "&:hover": { textDecoration: "underline" } }}>
          {data.serial}
        </Typography>
        <Button
          data-tooltip-id={`copyTooltip${data.serial}`}
          onClick={onCopy}
          sx={{
            minWidth: "unset !important",
            width: "10px",
            padding: "0 0 0 7px !important",
          }}>
          <ContentCopyIcon sx={{ fontSize: "14px" }} />
        </Button>
      </Box>
      <Handle type='target' position={Position.Left} id='b' />
      <ReactTooltip
        id={`copyTooltip${data.serial}`}
        place='bottom'
        style={{
          fontSize: "8px",
          height: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {copied ? "Copiado" : "Copiar al portapapeles"}
      </ReactTooltip>
    </Box>
  );
};

export default MeterNode;
