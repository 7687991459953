import { Dispatch, SetStateAction, useState } from "react";
import { ReportAttemps, ReportAttempsStates, XMReportsModel } from "../../models/reports";
import { Button, LoadingButton } from "@enerbit/base";
import { getReportAttemps, getReportState, sendXMReport } from "../../services";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateLastAttemp, updateReportAttempState } from "../../store/slices/reports";
import { getLastReportAttemp } from "../../helpers";

interface Props {
  attemp: ReportAttemps | null;
  report: XMReportsModel;
  setSuccess: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | undefined>>;
}

const ReportsFollowingModalActions = ({ attemp, report, setSuccess, setError }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const reports = useSelector((state: RootState) => state.reports.reports);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const sendReport = async (reportId: string) => {
    setError(undefined);
    setSuccess(false);
    setLoading(true);

    try {
      await sendXMReport(reportId);
      const attemps = await getReportAttemps(reportId);
      const lastAttemp = getLastReportAttemp(attemps);
      dispatch(updateLastAttemp({ reportId, lastAttemp }));
      setSuccess(true);
    } catch (error: any) {
      setError("Hubo un error al enviar el reporte. Intenta de nuevo");
    } finally {
      setLoading(false);
    }
  };

  const goToReportDetail = (reportId: string, asicCode: string) => {
    navigate(`/xm-report/${reportId}?asic_code=${asicCode}`);
  };

  const updateReportState = async (attemp: ReportAttemps, report: XMReportsModel) => {
    setLoadingState(true);
    try {
      const detail = await getReportState(attemp.process_id);
      const reportIndex = reports.findIndex((item) => item.id === report.id);
      dispatch(updateReportAttempState({ newState: detail.ResultFlag, reportIndex }));
    } catch (error) {
      setError("Error al consultar el estado del reporte.");
    } finally {
      setLoadingState(false);
    }
  };

  if (!attemp) {
    return (
      <LoadingButton
        fullWidth
        onClick={() => sendReport(report.id)}
        loading={loading}
        sx={{ height: "33px" }}
        variant='contained'
        color='secondary'>
        Enviar
      </LoadingButton>
    );
  } else if (
    attemp.state === ReportAttempsStates.SUCCESS ||
    attemp.state === ReportAttempsStates.WARNING
  ) {
    return (
      <Button
        fullWidth
        onClick={() => goToReportDetail(attemp.report_id, attemp.process_id)}
        sx={{ height: "33px" }}
        variant='contained'
        color='primary'>
        Ver detalle
      </Button>
    );
  } else if (attemp.state === ReportAttempsStates.WAITING) {
    return (
      <LoadingButton
        fullWidth
        sx={{ height: "33px" }}
        variant='contained'
        color='info'
        loading={loadingState}
        onClick={() => updateReportState(attemp, report)}>
        Consultar estado
      </LoadingButton>
    );
  }

  return <></>;
};

export default ReportsFollowingModalActions;
