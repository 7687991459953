import { useState } from "react";
import {
  Box,
  Button,
  CachedIcon,
  Grid,
  MenuItem,
  SearchIcon,
  Select,
  TextField,
  Typography,
} from "@enerbit/base";
import { SearchFilters } from "../../models/frontiers";
import { SEARCH_FILTERS } from "../../const";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getMissingReadingRecords, searchRecordsBySerial } from "../../store/thunks/frontiers";
import SicReportModal from "../SicReportModal/SicReportModal";
import RefustigateModal from "../RefustigateModal/RefustigateModal";
import { setSearchValue, updateSelectedFrts } from "../../store/slices/frontiers";
import ReportsFollowingModal from "../ReportsFollowingModal/ReportsFollowingModal";

interface Props {
  daysFilter: string;
}

const ReadingRecordsListActions = ({ daysFilter }: Props) => {
  const [searchFilter, setSearchFilter] = useState<SearchFilters>(SearchFilters.METER_SERIAL);
  const [openSicModal, setOpenSicModal] = useState<boolean>(false);
  const [openRefustigate, setOpenRefustigate] = useState<boolean>(false);
  const [openReports, setOpenReports] = useState<boolean>(false);

  const searchValue = useSelector((state: RootState) => state.frontiers.searchValue);

  const dispatch = useDispatch<AppDispatch>();

  const getMissingRecords = () => {
    dispatch(getMissingReadingRecords(daysFilter));
  };

  const handleSearch = () => {
    if (!searchValue) return;

    dispatch(searchRecordsBySerial({ days: daysFilter, value: searchValue, filter: searchFilter }));
  };

  const onRefresh = () => {
    getMissingRecords();
    dispatch(updateSelectedFrts([]));
    dispatch(setSearchValue(""));
  };

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={5}>
          <Box sx={{ display: "flex" }}>
            <Select
              value={searchFilter}
              onChange={(event) => setSearchFilter(event.target.value as SearchFilters)}
              sx={{
                "& fieldset": {
                  borderRadius: "12px 0px 0px 12px !important",
                },
              }}>
              {SEARCH_FILTERS.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              fullWidth
              placeholder='Buscar'
              value={searchValue}
              onChange={(event) => dispatch(setSearchValue(event.target.value))}
              sx={{ borderRadius: "unset" }}
              InputProps={{
                sx: {
                  "& fieldset": {
                    borderRadius: "0px !important",
                  },
                },
              }}
            />
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "0px 12px 12px 0px !important",
              }}
              onClick={handleSearch}
              variant='contained'
              color='primary'>
              <Typography>
                <SearchIcon />
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              borderRadius: "12px !important",
              height: "100%",
            }}
            fullWidth
            onClick={onRefresh}
            variant='contained'
            color='primary'>
            <CachedIcon />
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => setOpenSicModal(true)}
            sx={{ height: "51px", backgroundColor: "#3B82F6" }}
            fullWidth
            variant='contained'>
            Generar reporte para el ASIC
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            color='secondary'
            sx={{ height: "51px" }}
            fullWidth
            variant='contained'
            onClick={() => setOpenReports(true)}>
            Seguimiento de reportes
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            onClick={() => setOpenRefustigate(true)}
            sx={{ height: "51px" }}
            fullWidth
            variant='contained'
            color='primary'>
            Refustigación masiva
          </Button>
        </Grid>
      </Grid>
      <SicReportModal open={openSicModal} handleClose={() => setOpenSicModal(false)} />
      <ReportsFollowingModal
        open={openReports}
        handleClose={() => {
          setOpenReports(false);
        }}
      />
      <RefustigateModal
        open={openRefustigate}
        handleClose={() => setOpenRefustigate(false)}
        isBulk={true}
      />
    </>
  );
};

export default ReadingRecordsListActions;
