import {
  AdapterMoment,
  Box,
  Button,
  CloseIcon,
  CustomAlert,
  DatePicker,
  FormControl,
  LoadingButton,
  LocalizationProvider,
  Modal,
  Typography,
} from "@enerbit/base";
import "../../styles/index.css";
import { useState } from "react";
import { generateSicReport } from "../../services";
import moment from "moment";
import { enqueueSnackbar } from "notistack";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
};

interface Props {
  open: boolean;
  handleClose: () => void;
}

const SicReportModal = ({ open, handleClose }: Props) => {
  const [date, setDate] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onGenerateSicReport = async () => {
    if (!date) return;
    setLoading(true);
    setError(false);

    try {
      await generateSicReport(date);
      onClose();
      enqueueSnackbar("Iniciado el proceso de generación de informes", { variant: "success" });
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setLoading(false);
    setError(false);
    setDate("");
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
              Generar reporte para el ASIC
            </Typography>
            <Button onClick={onClose} variant='outlined' className='back-btn'>
              <CloseIcon />
            </Button>
          </Box>
          <Box my={2}>
            <Typography
              sx={{
                paddingBottom: "10px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16px",
                color: "#3D4350",
              }}>
              Elige el día
            </Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format='YYYY-MM-DD'
                  value={moment(date)}
                  disabled={loading}
                  maxDate={moment()}
                  onChange={(value: any) => {
                    setDate(value.format("YYYY-MM-DD"));
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      sx: { "& fieldset": { borderRadius: "12px" } },
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          {error && (
            <Box mb={1}>
              <CustomAlert
                severity='error'
                text={"Hubo un error generando el informe."}
                onClose={() => setError(false)}
              />
            </Box>
          )}
          <LoadingButton
            fullWidth
            variant='contained'
            sx={{ backgroundColor: "#3B82F6" }}
            loading={loading}
            disabled={!date || loading}
            onClick={onGenerateSicReport}>
            Generar informe
          </LoadingButton>
        </Box>
      </Modal>
    </>
  );
};

export default SicReportModal;
