import {
  ArrowForwardIcon,
  Box,
  Button,
  CircularProgress,
  CloseIcon,
  CustomAlert,
  Modal,
  Pagination,
  Paper,
  SearchIcon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  styled,
} from "@enerbit/base";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { BorderResult, ResultFlags } from "../../models/reports";
import { getReportDetail } from "../../store/thunks/reports";
import { setFetchError } from "../../store/slices/reports";
import PaginationContainer from "../../components/PaginationContainer/PaginationContainer";
import ReportResultHeader from "../../components/ReportResultHeader/ReportResultHeader";
import ReportResultsSearchBar from "../../components/ReportResultsSearchBar/ReportResultsSearchBar";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "550px",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
};

const ELEMENTS_PER_PAGE = 50;

const TABLE_HEADERS: { title: string; align: string; sortable: boolean }[] = [
  { title: "Frontera", align: "left", sortable: false },
  { title: "Fecha", align: "left", sortable: false },
  { title: "Resultado", align: "center", sortable: false },
  { title: "Descripción", align: "center", sortable: false },
];

const XMReportsDetail = () => {
  const { proccessId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const asicCode = queryParams.get("asic_code");

  const xmResults = useSelector((state: RootState) => state.reports.reportResults);
  const tempXmResults = useSelector((state: RootState) => state.reports.tempReportResults);
  const loading = useSelector((state: RootState) => state.reports.loading);
  const error = useSelector((state: RootState) => state.reports.error);

  const [openDescription, setOpenDescription] = useState<boolean>(false);
  const [selectedResult, setSelectedResult] = useState<BorderResult>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dispatch = useDispatch<AppDispatch>();

  const startIndex = useMemo(() => {
    return (currentPage - 1) * ELEMENTS_PER_PAGE;
  }, [currentPage]);

  const endIndex = useMemo(() => {
    return startIndex + ELEMENTS_PER_PAGE;
  }, [currentPage]);

  const navigate = useNavigate();

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    setCurrentPage(value);
  };

  const getDetail = async () => {
    if (!asicCode) return;
    await dispatch(getReportDetail(asicCode));
  };

  const goBack = () => {
    navigate("/");
  };

  useEffect(() => {
    getDetail();
  }, []);

  return (
    <>
      <ReportResultHeader proccessId={proccessId} asicCode={asicCode} goBack={goBack} />
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} my={2}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box my={2}>
          <CustomAlert
            text='Error al mostrar los resultados del reporte'
            severity='error'
            onClose={() => dispatch(setFetchError(false))}
          />
        </Box>
      )}
      {tempXmResults && !loading && !error && (
        <Box>
          <Box mt={3}>
            <ReportResultsSearchBar />
          </Box>
          <Typography color='primary' fontSize={22} mt={3}>
            Lista de fronteras
          </Typography>
          {tempXmResults.Results.BorderResult.length > 0 && (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead sx={{ ".MuiTableCell-root": { padding: "7px !important" } }}>
                    <TableRow>
                      {TABLE_HEADERS.map((item) => (
                        <TableCell sx={{ textAlign: item.align }} key={item.title}>
                          {item.sortable ? (
                            <TableSortLabel active={true}>{item.title}</TableSortLabel>
                          ) : (
                            item.title
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ ".MuiTableCell-root": { padding: "7px !important" } }}>
                    {tempXmResults.Results.BorderResult.slice(startIndex, endIndex).map(
                      (result) => (
                        <TableRow key={result.Code}>
                          <TableCell sx={{ width: "30%" }}>{result.Code}</TableCell>
                          <TableCell sx={{ width: "30%" }}>2023/06/01</TableCell>
                          <TableCell
                            sx={{
                              width: "30%",
                            }}>
                            <StatusIndicator
                              bgcolor={
                                result.ResultFlag === ResultFlags.SUCCESS ? "#E7F8F0" : "#FEECEB"
                              }
                              color={
                                result.ResultFlag === ResultFlags.SUCCESS ? "#12B76A" : "#F04438"
                              }>
                              {result.ResultFlag === ResultFlags.SUCCESS ? "Exitoso" : "Error"}
                            </StatusIndicator>
                          </TableCell>
                          <TableCell sx={{ width: "10%", textAlign: "center" }}>
                            <Button
                              sx={{ height: "33px", width: "32px", minWidth: "unset !important" }}
                              variant='contained'
                              onClick={() => {
                                setSelectedResult(result);
                                setOpenDescription(true);
                              }}
                              color='primary'>
                              <ArrowForwardIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationContainer>
                <Stack spacing={2}>
                  <Pagination
                    page={currentPage}
                    count={Math.round(
                      tempXmResults.Results.BorderResult.length / ELEMENTS_PER_PAGE
                    )}
                    shape='rounded'
                    onChange={handlePagination}
                  />
                </Stack>
              </PaginationContainer>
            </>
          )}
        </Box>
      )}
      {selectedResult && (
        <Modal open={openDescription} onClose={() => setOpenDescription(false)}>
          <Box sx={style}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
                Detalle {selectedResult.Code}
              </Typography>
              <Button
                onClick={() => setOpenDescription(false)}
                variant='outlined'
                className='back-btn'>
                <CloseIcon />
              </Button>
            </Box>
            <Typography sx={{ textAlign: "justify", mt: 2, color: "#667085", fontSize: "16px" }}>
              {selectedResult.ErrorMessage}
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default XMReportsDetail;

interface StatusColor {
  bgcolor: string;
  color: string;
}

const StatusIndicator = styled("div")<StatusColor>(({ bgcolor, color }) => ({
  height: "27px",
  backgroundColor: bgcolor,
  color: color,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
}));
