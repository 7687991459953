import {
  AdapterMoment,
  Box,
  Button,
  CircularProgress,
  CloseIcon,
  CustomAlert,
  DatePicker,
  FormControl,
  Grid,
  LocalizationProvider,
  Modal,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@enerbit/base";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { getAsicReports } from "../../store/thunks/reports";
import { resetState, setEmptyReports, setFetchError } from "../../store/slices/reports";
import { useMemo, useState } from "react";
import { ReportAttempsStates, XMReportsModel } from "../../models/reports";
import { REPORT_ATTEMPS_STATES } from "../../const";
import PaginationContainer from "../PaginationContainer/PaginationContainer";
import ReportsFollowingModalActions from "./ReportsFollowingModalActions";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "950px",
  bgcolor: "background.paper",
  p: 3,
  borderRadius: "10px",
};

const TABLE_HEADERS = ["Id reporte", "Fecha último intento", "Estado último intento", "Acciones"];
const ELEMENTS_PER_PAGE = 5;

const ReportsFollowingModal = ({ open, handleClose }: Props) => {
  const reports = useSelector((state: RootState) => state.reports.reports);
  const loadingReports = useSelector((state: RootState) => state.reports.loading);
  const reportsDate = useSelector((state: RootState) => state.reports.reportDate);
  const empty = useSelector((state: RootState) => state.reports.emptyReports);
  const fetchError = useSelector((state: RootState) => state.reports.error);

  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [date, setDate] = useState<string>("");

  const startIndex = useMemo(() => {
    return (currentPage - 1) * ELEMENTS_PER_PAGE;
  }, [currentPage]);

  const endIndex = useMemo(() => {
    return startIndex + ELEMENTS_PER_PAGE;
  }, [currentPage]);

  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    setCurrentPage(value);
  };

  const dispatch = useDispatch<AppDispatch>();

  const onClose = () => {
    handleClose();
    setDate("");
    setSuccess(false);
    setError(undefined);
    setCurrentPage(1);
    dispatch(setEmptyReports(false));
    dispatch(resetState({}));
  };

  const handleSearch = async () => {
    if (!date) return;

    setError(undefined);
    setSuccess(false);
    dispatch(setEmptyReports(false));

    await dispatch(getAsicReports(date));
    setCurrentPage(1);
  };

  const renderLastReportState = (state: string) => {
    const reportStateColors = REPORT_ATTEMPS_STATES[state as ReportAttempsStates];
    return (
      <StatusIndicator bgcolor={reportStateColors.bgcolor} color={reportStateColors.fontcolor}>
        {reportStateColors.label}
      </StatusIndicator>
    );
  };

  const renderLastReportAttemp = (report: XMReportsModel) => {
    return (
      <TableRow key={report.id}>
        <TableCell>{report.id}</TableCell>
        <TableCell>
          {report.last_attemp
            ? moment(report.last_attemp.created_at).format("YYYY-MM-DD")
            : "Sin fecha"}
        </TableCell>
        <TableCell>
          {renderLastReportState(report.last_attemp?.state ?? ReportAttempsStates.NO_SENT)}
        </TableCell>
        <TableCell>
          <ReportsFollowingModalActions
            attemp={report.last_attemp}
            report={report}
            setError={setError}
            setSuccess={setSuccess}
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
          <Typography color={"primary"} sx={{ fontSize: "21px", fontWeight: 700 }}>
            Seguimiento de reportes
          </Typography>
          <Button onClick={onClose} variant='outlined' className='back-btn'>
            <CloseIcon />
          </Button>
        </Box>
        <Grid mb={2} container spacing={2}>
          <Grid item xs={10}>
            <Typography
              sx={{
                paddingBottom: "10px",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "16px",
              }}>
              Fecha de reporte
            </Typography>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format='YYYY-MM-DD'
                  value={moment(date)}
                  maxDate={moment()}
                  onChange={(value: any) => {
                    setDate(value ? value.format("YYYY-MM-DD") : "");
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: false,
                      sx: {
                        "& fieldset": { borderRadius: "12px" },
                        ".MuiInputBase-root": { height: "41px" },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              sx={{ mt: 3, height: "41px", color: "white" }}
              color='success'
              variant='contained'
              onClick={handleSearch}>
              Buscar
            </Button>
          </Grid>
        </Grid>
        {error && <CustomAlert text={error} onClose={() => setError(undefined)} severity='error' />}
        {fetchError && (
          <CustomAlert
            text={"Error consultando reportes generados."}
            onClose={() => dispatch(setFetchError(false))}
            severity='error'
          />
        )}
        {success && (
          <CustomAlert
            text={"Reporte enviado con éxito."}
            onClose={() => setSuccess(true)}
            severity='success'
          />
        )}
        {!loadingReports && empty && (
          <Box>
            <CustomAlert
              severity='info'
              onClose={() => dispatch(setEmptyReports(false))}
              text={"No se encontraron reportes generados para esta fecha."}
            />
          </Box>
        )}
        {loadingReports && (
          <Box my={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {!loadingReports && !empty && reports.length > 0 && (
          <Box my={1}>
            <Typography color='primary' sx={{ fontSize: "18px", mb: 1 }}>
              Reportes generados
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ ".MuiTableCell-root": { padding: "10px !important" } }}>
                  <TableRow>
                    {TABLE_HEADERS.map((item) => (
                      <TableCell key={item}>{item}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody sx={{ ".MuiTableCell-root": { padding: "10px !important" } }}>
                  {reports.slice(startIndex, endIndex).map((item) => renderLastReportAttemp(item))}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationContainer>
              <Stack spacing={2}>
                <Pagination
                  page={currentPage}
                  count={Math.round(reports.length / ELEMENTS_PER_PAGE)}
                  shape='rounded'
                  onChange={handlePagination}
                />
              </Stack>
            </PaginationContainer>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ReportsFollowingModal;

interface StatusColor {
  bgcolor: string;
  color: string;
}

const StatusIndicator = styled("div")<StatusColor>(({ bgcolor, color }) => ({
  width: "135px",
  height: "27px",
  backgroundColor: bgcolor,
  color: color,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
}));
