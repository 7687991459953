import { Box, Button, Typography } from "@enerbit/base";
import { Handle, Position } from "reactflow";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

const SmartbitNode = ({ data }: any) => {
  const [copied, setCopied] = useState<boolean>(false);

  const onCopy = () => {
    copy(data.deviceId);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box sx={{ width: "150px", borderColor: "red", backgroundColor: "#fff", borderRadius: "3px" }}>
      <Box sx={{ width: "100%", padding: "5px" }}>
        <Typography sx={{ fontSize: "7px", color: "#667085" }}>smartBit</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#E4E7EC",
          padding: "5px",
          height: "45px",
          borderRadius: "0px 0px 3px 3px",
          textAlign: "center",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}>
        <Typography color={"primary"} fontWeight={700} sx={{ fontSize: "8px", userSelect: "text" }}>
          {data.deviceId}
        </Typography>
        <Button
          data-tooltip-id='copyTooltipB'
          onClick={onCopy}
          sx={{
            minWidth: "unset !important",
            width: "10px",
          }}>
          <ContentCopyIcon sx={{ fontSize: "14px" }} />
        </Button>
      </Box>
      <Handle type='source' position={Position.Right} id='a' />
      <ReactTooltip
        id='copyTooltipB'
        place='bottom'
        style={{
          fontSize: "8px",
          height: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        {copied ? "Copiado" : "Copiar al portapapeles"}
      </ReactTooltip>
    </Box>
  );
};

export default SmartbitNode;
