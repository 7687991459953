import { Pagination, Stack } from "@enerbit/base";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setCurrentPage } from "../../store/slices/events";
import { useLocation } from "react-router-dom";
import { getMeterEvents } from "../../store/thunks/events";

interface Props {
  serial: string;
}

const EventsPagination = ({ serial }: Props) => {
  const totalPages = useSelector((state: RootState) => state.events.totalPages);
  const currentPage = useSelector((state: RootState) => state.events.currentPage);
  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    dispatch(setCurrentPage(value - 1));
    dispatch(
      getMeterEvents({
        meterSerial: serial,
      })
    );
  };

  return (
    <Stack spacing={2}>
      <Pagination
        page={currentPage + 1}
        count={totalPages}
        shape='rounded'
        onChange={handleChange}
      />
    </Stack>
  );
};

export default EventsPagination;
