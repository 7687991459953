// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-btn {
  border-radius: 50% !important;
  height: 41px !important;
  width: 41px !important;
  min-width: unset !important;
}

/* .MuiTableRow-root th {
  background-color: #efe9fb !important;
  padding: 8px 16px !important;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.MuiTableCell-root {
  border-bottom: unset !important;
}

.MuiInputBase-root {
  background-color: white;
  border-radius: 12px !important;
} */
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;;;;;;;;;;;;;;;;GAgBG","sourcesContent":[".back-btn {\n  border-radius: 50% !important;\n  height: 41px !important;\n  width: 41px !important;\n  min-width: unset !important;\n}\n\n/* .MuiTableRow-root th {\n  background-color: #efe9fb !important;\n  padding: 8px 16px !important;\n}\n\n.MuiPaper-root {\n  box-shadow: none !important;\n}\n\n.MuiTableCell-root {\n  border-bottom: unset !important;\n}\n\n.MuiInputBase-root {\n  background-color: white;\n  border-radius: 12px !important;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
