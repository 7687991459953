import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Menu,
  MenuProps,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@enerbit/base";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getMissingReadingRecords } from "../../store/thunks/frontiers";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setIncludeFrt, setSearchValue } from "../../store/slices/frontiers";
import { INCLUDE_NOFRT_FILTER, INTERVALS_DAYS_BEFORE } from "../../const";

interface Props {
  days: string;
  setDays: React.Dispatch<React.SetStateAction<string>>;
  frtFilter: boolean;
  setFrtFilter: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 220,
    padding: "2px",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.75);",
  },
}));

const TableDaysFilter = ({ days, setDays, frtFilter, setFrtFilter }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const currentDays = useSelector((state: RootState) => state.frontiers.days);
  const includeFrt = useSelector((state: RootState) => state.frontiers.includeFrt);

  const dispatch = useDispatch<AppDispatch>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDays(currentDays);
    setFrtFilter(includeFrt);
  };

  const onApplyFilter = () => {
    dispatch(setIncludeFrt(frtFilter));
    dispatch(getMissingReadingRecords(days));
    dispatch(setSearchValue(""));
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={{
          minHeight: "unset",
          borderRadius: "12px",
          border: "1px solid #A3A9B6",
          height: "32px",
          display: "felx",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
        onClick={handleClick}>
        <FilterListIcon color='primary' />
        <Typography sx={{ fontSize: "12px", color: "#14161B" }}>Filtros</Typography>
        <Badge>
          <Typography sx={{ color: "#fff", fontSize: "10px" }}>{days} días</Typography>
        </Badge>
        <Badge>
          <Typography sx={{ color: "#fff", fontSize: "10px" }}>
            {includeFrt ? "Con NOFRT" : "Sin NOFRT"}
          </Typography>
        </Badge>
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", flexDirection: "column" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        <FormControl sx={{ padding: "7px" }}>
          <FormLabel>Filtrar días</FormLabel>
          <RadioGroup
            value={days}
            onChange={(event) => {
              setDays(event.target.value);
            }}>
            {INTERVALS_DAYS_BEFORE.map((item) => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
          <FormLabel>Incluir NOFRT</FormLabel>
          <RadioGroup
            value={frtFilter}
            onChange={(event) => {
              const newValue = event.target.value === "true";
              setFrtFilter(newValue);
            }}>
            {INCLUDE_NOFRT_FILTER.map((item, index) => (
              <FormControlLabel
                key={index}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ))}
          </RadioGroup>
          <Button
            variant='contained'
            color='primary'
            onClick={onApplyFilter}
            sx={{
              minHeight: "unset",
              borderRadius: "12px",
              height: "32px",
            }}>
            Aplicar
          </Button>
        </FormControl>
      </StyledMenu>
    </>
  );
};

export default TableDaysFilter;

const Badge = styled("div")`
  border-radius: 10px;
  background-color: #53358e;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 20px;
`;
