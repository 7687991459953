import {
  AdapterMoment,
  Box,
  Button,
  CircularProgress,
  DatePicker,
  FormControl,
  Grid,
  LocalizationProvider,
  Paper,
  SearchIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@enerbit/base";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import EventsPagination from "./EventsPagination";
import { setSince, setUntil } from "../../store/slices/events";
import { useLocation, useParams } from "react-router-dom";
import { getMeterEvents } from "../../store/thunks/events";
import { utcToLocal } from "../../helpers";
import "../FrontiersTable/FrontiersTable.css";
import PaginationContainer from "../PaginationContainer/PaginationContainer";
import moment from "moment";

const EventsTable = () => {
  const events = useSelector((state: RootState) => state.events.events);
  const since = useSelector((state: RootState) => state.events.since);
  const until = useSelector((state: RootState) => state.events.until);
  const loadingEvents = useSelector((state: RootState) => state.events.isLoading);

  const { serial } = useParams();

  const dispatch = useDispatch<AppDispatch>();

  const getEvents = () => {
    if (!since || !until || !serial) return;

    dispatch(
      getMeterEvents({
        meterSerial: serial,
      })
    );
  };

  useEffect(() => {
    if (!serial) return;

    dispatch(
      getMeterEvents({
        meterSerial: serial,
      })
    );
  }, []);

  return (
    <Box>
      {!loadingEvents && serial && (
        <>
          <Grid mb={3} container spacing={3}>
            <Grid item md={6} xs={12}>
              <Typography
                sx={{
                  paddingBottom: "10px",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                }}>
                Fecha inicial
              </Typography>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format='YYYY/MM/DD'
                    value={moment(since)}
                    onChange={(value: any) => {
                      dispatch(setSince(value?.format("YYYY-MM-DD")));
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: false,
                        sx: { "& fieldset": { borderRadius: "12px" } },
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                sx={{
                  paddingBottom: "10px",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "16px",
                }}>
                Fecha final
              </Typography>
              <Grid container spacing={3}>
                <Grid item flex={1}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        format='YYYY-MM-DD'
                        value={moment(until)}
                        onChange={(value: any) => {
                          dispatch(setUntil(value?.format("YYYY-MM-DD")));
                        }}
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            error: false,
                            sx: { "& fieldset": { borderRadius: "12px" } },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item mt={0.5}>
                  <Button color='primary' variant='contained' onClick={getEvents}>
                    <SearchIcon />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Evento</TableCell>
                  <TableCell align='left'>Fecha</TableCell>
                  <TableCell align='left'>Descripción</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event) => (
                  <TableRow
                    key={event.message_uuid}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align='left'>{JSON.parse(event.event_payload).type}</TableCell>
                    <TableCell align='left'>{utcToLocal(event.time_sender)}</TableCell>
                    <TableCell align='left'>{JSON.parse(event.event_payload).reason}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {serial && (
            <PaginationContainer>
              <EventsPagination serial={serial} />
            </PaginationContainer>
          )}
        </>
      )}
      {loadingEvents && (
        <Box my={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default EventsTable;
