import {
  ArrowForwardIcon,
  Button,
  Checkbox,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@enerbit/base";
import "./FrontiersTable.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { appendFrt, setCurrentPage, updateSelectedFrts } from "../../store/slices/frontiers";
import { useNavigate } from "react-router-dom";
import { getMissingReadingRecords } from "../../store/thunks/frontiers";
import { ChangeEvent, useEffect, useMemo } from "react";
import { MissingReadingRecords } from "../../models/frontiers";
import PaginationContainer from "../PaginationContainer/PaginationContainer";

interface Props {
  days: string;
  filter: string;
}

const FrontiersTable = ({ days, filter }: Props) => {
  const totalPages = useSelector((state: RootState) => state.frontiers.totalPages);
  const currentPage = useSelector((state: RootState) => state.frontiers.currentPage);
  const frontiers = useSelector((state: RootState) => state.frontiers.frontiers);
  const selectedFrts = useSelector((state: RootState) => state.frontiers.selectedFrts);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    dispatch(setCurrentPage(value - 1));
    dispatch(getMissingReadingRecords(filter));
  };

  const handleCheck = (event: ChangeEvent<HTMLInputElement>, frt: MissingReadingRecords) => {
    if (event.target.checked) {
      const tempFrts = [...selectedFrts];
      tempFrts.push(frt);
      dispatch(updateSelectedFrts(tempFrts));
    } else {
      const tempFrts = selectedFrts.filter((item) => item.meter_serial !== frt.meter_serial);
      dispatch(updateSelectedFrts(tempFrts));
    }
  };

  const checkAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      frontiers.map((item) => {
        const exists = selectedFrts.some((frt) => frt.meter_serial === item.meter_serial);
        if (!exists) {
          dispatch(appendFrt(item));
        }
      });
    } else {
      const tempFrts = selectedFrts.filter(
        (item) => !frontiers.some((frt) => frt.meter_serial === item.meter_serial)
      );
      dispatch(updateSelectedFrts(tempFrts));
    }
  };

  const TABLE_HEADERS: string[] = [
    "Frontera",
    "Serial del medidor",
    `Reportes de consumo (últimos ${days} días)`,
    "Último reporte de consumo",
    "Estado",
    "Detalle",
  ];

  const checkedAll = useMemo(() => {
    return frontiers.every((item) =>
      selectedFrts.some((frt) => frt.meter_serial === item.meter_serial)
    );
  }, [frontiers, selectedFrts]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  color='secondary'
                  inputProps={{ "aria-label": "controlled" }}
                  onChange={checkAll}
                  checked={checkedAll}
                />
              </TableCell>
              {TABLE_HEADERS.map((item, index) => (
                <TableCell key={index}>{item}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {frontiers.map((frt) => (
              <TableRow key={frt.frt_code}>
                <TableCell>
                  <Checkbox
                    color='secondary'
                    checked={selectedFrts.some((item) => item.meter_serial === frt.meter_serial)}
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(event) => handleCheck(event, frt)}
                  />
                </TableCell>
                <TableCell>{frt.frt_code}</TableCell>
                <TableCell>{frt.meter_serial}</TableCell>
                <TableCell>{frt.count_records}</TableCell>
                <TableCell>{frt.max_date}</TableCell>
                <TableCell>
                  <StatusIndicator status={frt.count_records === +days ? "complete" : "incomplete"}>
                    {frt.count_records === +days ? "Completo" : "Incompleto"}
                  </StatusIndicator>
                </TableCell>
                <TableCell>
                  <Button
                    variant='contained'
                    color='primary'
                    sx={{
                      minWidth: "unset !important",
                      height: "30px",
                      width: "50px",
                      borderRadius: "10px !important",
                    }}
                    onClick={() => {
                      navigate(`/detail/${frt.meter_serial}`);
                    }}>
                    <ArrowForwardIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer>
        <Stack spacing={2}>
          <Pagination
            page={currentPage + 1}
            count={totalPages}
            shape='rounded'
            onChange={handleChange}
          />
        </Stack>
      </PaginationContainer>
    </>
  );
};

export default FrontiersTable;

interface StatusColor {
  status: string;
}

const StatusIndicator = styled("div")<StatusColor>(({ status }) => ({
  width: "122px",
  height: "27px",
  backgroundColor: status === "complete" ? "#D1FADF" : "#FEE4E2",
  color: status === "complete" ? "#12B76A" : "#F04438",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
}));
