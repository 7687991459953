import { Slice, createSlice } from "@reduxjs/toolkit";
import {
  FrontiersState,
  GetRestoreSchedulesStatusModel,
  RestoreSchedulesStatusModel,
} from "../../models/frontiers";
import {
  getMissingReadingRecords,
  getRestoreSechedulesStatus,
  searchRecordsBySerial,
} from "../thunks/frontiers";
import { DEFAULT_DAYS_BEFORE, DEFAULT_INCLUDE_NOFRT, RESTORE_SCHEDULES_STATUS } from "../../const";

const initialState: FrontiersState = {
  frontiers: [],
  totalPages: 0,
  currentPage: 0,
  error: false,
  isLoading: false,
  days: DEFAULT_DAYS_BEFORE,
  selectedFrts: [],
  includeFrt: DEFAULT_INCLUDE_NOFRT,
  frtMessage: "Sin NOFRT",
  searchValue: "",
  loadingRestoreStatus: false,
  errorRestoreStatus: false,
  restoreScheduleStatus: {} as GetRestoreSchedulesStatusModel,
  restoreSchedulesStatusColors: {} as RestoreSchedulesStatusModel,
};

export const frontiersSlice: Slice<FrontiersState> = createSlice({
  name: "frontiers",
  initialState,
  reducers: {
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setDays(state, { payload }) {
      state.days = payload;
    },
    updateSelectedFrts(state, { payload }) {
      state.selectedFrts = payload;
    },
    appendFrt(state, { payload }) {
      state.selectedFrts.push(payload);
    },
    setIncludeFrt(state, { payload }) {
      state.includeFrt = payload;
    },
    setFrtMessage(state, { payload }) {
      state.frtMessage = payload;
    },
    setSearchValue(state, { payload }) {
      state.searchValue = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMissingReadingRecords.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(getMissingReadingRecords.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = false;
      state.frontiers = payload.data.items;
      state.totalPages = payload.data.pages;
      state.days = payload.days;
    });
    builder.addCase(getMissingReadingRecords.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(searchRecordsBySerial.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(searchRecordsBySerial.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = false;
      state.frontiers = payload.items;
      state.totalPages = payload.pages;
    });
    builder.addCase(searchRecordsBySerial.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    builder.addCase(getRestoreSechedulesStatus.pending, (state) => {
      state.restoreSchedulesStatusColors = RESTORE_SCHEDULES_STATUS.loading;
      state.loadingRestoreStatus = true;
      state.errorRestoreStatus = false;
    });
    builder.addCase(getRestoreSechedulesStatus.fulfilled, (state, { payload }) => {
      state.loadingRestoreStatus = false;
      state.errorRestoreStatus = false;
      state.restoreScheduleStatus = payload;
      state.restoreSchedulesStatusColors = RESTORE_SCHEDULES_STATUS[payload.state];
    });
    builder.addCase(getRestoreSechedulesStatus.rejected, (state) => {
      state.loadingRestoreStatus = false;
      state.errorRestoreStatus = true;
      state.restoreSchedulesStatusColors = RESTORE_SCHEDULES_STATUS.error;
    });
  },
});

export const {
  setCurrentPage,
  setDays,
  updateSelectedFrts,
  appendFrt,
  setIncludeFrt,
  setFrtMessage,
  setSearchValue,
} = frontiersSlice.actions;
